// Here you can add other styles
.admin-buttons-row {
  margin-bottom: 1rem;
}

.refresh-blackouts-response {
  width: 20px;
}

#admin-buttons-row {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  grid-template-rows: 40px 15px;
  grid-gap: 10px;
}

.response-success {
  color: green;
}

.response-failure {
  color: red;
}

.team-card {
  width: 100%;
  height: 60px;
  background-color: #F7F7F7;
  border: 1px solid #CACACA;
  transition: height .3s ease;
  padding:25px;
  padding-top:10px;
  margin:12px;
  overflow: hidden;
}

.team-card-expanded {
  height: 300px;
}

.team-card-expanded > .team-card-team-text {
  border-bottom: 2px solid #20a8d8;
}

.team-card-input {
  width: 150px;
  height: 35px;
  border-radius: 3px;
  border: 1px solid grey;
}

.team-name {
  display: inline-block;
  margin-right: 10px;
  color: #AAA;
}
.team-city {
  display: inline-block;
  margin-right: 10px;
  color: #7AD;
}
.team-league {
  display: inline-block;
  margin-right: 10px;
  color: #777;
}

.team-card-team-text {
  position: relative;
  box-sizing: border-box;
  padding-top: 5px;
  font-size: 1.5em;
  height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  width:100%;

}

.team-card-edit-icon{
  position: absolute;
  top:0px;
  right:0px;
  width:30px;
  height: 40px;
  font-size: 1.5em;
  color: #777;
  transition: color .3s ease;
  cursor:pointer;
}
.team-card-edit-icon:hover{
  color: #56a756;
}

.team-card-team-data {
  margin: 0 auto;
}

.team-card-team-variants {
  width: 100%;
  min-height: 65px;
  margin: 0 auto;
  border:1px solid #777;
  padding:10px;
  background-color: #FFF;
}

.team-card-variant-tag:hover {
  background-color: #ff7777;
  color: #FFF;
}
.team-card-variant-tag:hover > .team-card-team-variant-circle {
  color: #FFF;
}

.team-card-team-variant-add {
  display: inline-block;
  width:100px;
  border:1px solid #DDD;
  padding: 4px;
  margin-top: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.team-card-team-variant-submit:focus {
  outline-width: 0;

}

.team-card-team-variant-submit {
  display: inline-block;
  width:35px;
  border:1px solid #9D9;
  padding: 4px;
  margin-right: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition-property: background-color;
  transition: all .3s ease;
  cursor: pointer;

}

.team-card-team-variant-submit:hover {
  background-color: #9D9;
}
.team-card-team-variant-circle {
  color: #ff7777;
  margin-right:5px;
  transition: color .3s ease;
}

.team-card-variant-tag {
  display: inline-block;
  border:1px solid #ffaeae;
  padding: 4px;
  margin: 4px;
  margin-right: 10px;
  margin-left: 0px;
  color: #C99;
  border-radius: 5px;
  transition-property: background-color color;
  transition: all .3s ease;
  cursor: pointer;
}
.team-card-row {
  display: flex;
  width:100%;
  justify-content: space-between;
}

.team-card-field {
  display: inline-block;
  margin-bottom: 10px;
}

.team-card-field-title {
  display: inline-block;
  font-weight: 600;
}

.team-card-field-data {
  display: inline-block;
  color: #777;
  margin-left: 5px;
  border: 1px solid #AAA;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFF;
}


.team-card-save-button {
  float: right;
  background-color: #56a756;
  padding: 4px;
  margin: 4px;
  margin-top:15px;
  width: 120px;
  border-radius: 5px;
  color: #FFF;
  transition-property: background-color;
  transition: all .3s ease;
  cursor: pointer;
  text-align: center;
  font-weight:600;
}

.team-card-save-button:hover {
  background-color: #95d495;
}

.team-card-notification-icon {
  display: inline-block;
  font-size: 1.75em;
  color: #DDD;
}

.team-card-notification-text {
  display: inline-block;
  font-size: .85em;
  color: #56a756;
  min-width: 200px;
  vertical-align: super;
  margin-left: 15px;
}

.error-text {
  color: #ef8585;
}

.team-card-notification-container {
  position: relative;
  height: 45px;
  padding-top: 17px;
}

.create-game-main, .update-content-main {
  width: 75%;
  margin: 0 auto;
}

.ui-kit-input {
  height: 3.125rem;
  border: solid;
  border-width: 2px;
  border-color: #1f1f1f;
  border-radius: 0;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  padding: 0.625rem;
  font-size: 1rem;
  color: #000000;
  background-color: #ffffff;
  cursor: text;
}

.drop-down-item {
  border: 1px solid #777;
  width: 100%;
  padding: 0.625rem;
  font-size: 1rem;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
}
.drop-down-item:hover {
  background-color: #EEE;
}

.drop-down-menu-container {
  position: relative;
}

.drop-down-menu {
  position: absolute;
}

.drop-down-league {
  display: inline-block;
  pointer-events: none;
}
.drop-down-team-name {
  display: inline-block;
  pointer-events: none;
}

.date-time-group {
  border:2px solid #000;
  border-top:0px;
  background-color: #FFF;
}

.error {
  border:2px solid red;
}

.date-time-calendar {
  width: 330px;
  margin: 0 auto;
}
.date-time-calendar > .m-input-moment{
  border: 0px;
}
.checkboxFive {
  width: 25px;
  height:50px;
  position: relative;
}

.checkboxFive label {
  cursor: pointer;
  position: absolute;
  width: 36px;
  height: 36px;
  top: 0;
  left: 0;
  margin-top: 2px;
  background: #FFF;
  border:2px solid #000;
  transition-property: background-color, color;
  transition: all .3s ease;
}
.checkboxFive label:after {
  opacity: 0.2;
  content: '';
  position: absolute;
  width: 16px;
  height: 9px;
  background: transparent;
  top: 8px;
  left: 8px;
  border: 3px solid #272;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkboxFive label:hover::after {
    opacity: 0.5;
}

.checkboxFive input[type=checkbox]:checked + label:after {
    opacity: 1;
    border: 3px solid #FFF;
    border-top: none;
    border-right: none;
}

.checkboxFive input[type=checkbox]:checked + label {
    background: #272;
}

.float-left {
  float: left;
}

.checkBox-label {
  position:absolute;
  top:5px;
  left:45px;
}

.date-filter {
}
.date-filter-input{
  display: inline-block;
  margin:10px;
  border:1px solid #777;
  width:35px;
  background-color: #EEE;
  text-align: center;
}
.date-filter-label{
  display: inline-block;
}

.loading {
  margin: 0 auto;
  font-size: 3em;
  width:50px;
  color: #DDD;
}

.disabled {
  pointer-events: none;
  background-color: #56a756;
}

.circle-notch {
  width: 150px;
  border-radius: 15px;
  padding:5px;
  text-align: center;
  cursor:pointer;
  margin:12px;
  background-color: #56a756;
  color: #FFF;
}

.circle-notch:hover {
  background-color: #95d495;
  color: #FFF;
}


.error-card-id{
  display: inline-block;
  margin-right:25px;
  font-size: 1.2em;
  font-weight: 600;
  vertical-align: top;

}
.error-card-date{
  display: inline-block;
  font-size: 1.2em;
  color: #777;
  font-weight: 600;
  width: 355px;
  margin-right:5px;
  vertical-align: top;

}
.error-card-description{
  display: inline-block;
  color: #A77;
  font-size: 1.2em;
  max-width: 50%;
  vertical-align: top;

}

.error-card {
  width: 100%;
  background-color: #F7F7F7;
  border: 1px solid #CACACA;
  transition: height .3s ease;
  padding:25px;
  padding-top:10px;
  margin:12px;
  overflow: hidden;
}
.date-icon {
  font-size: 39px;
  line-height: 30px;
  margin-left: -2px;
  transition: opacity .3s ease;
}

.date-icon:hover {
  opacity:.5;
}

.relative-row {
  position:relative;
}
.call-sign-checkbox-container-label{
  position:absolute;
  top:35px;
  right: 50px;
}
.call-sign-checkbox-container{
  position:absolute;
  top:28px;
  right: 18px;
}

.to-uppercase {
  text-transform: uppercase;
}

.row-spacing {
  margin-top: 15px;
}

.two-column {
  width: 45%;
  display: inline-block;
}

.two-column:last-child {
  float: right;
}

.schedule-date {
  border-left:0px;
  border-right:0px;
}
.faded {
  color: #F99;
}

label {
  margin: 0
}

.custom-label {
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  color: #353535;
  font-size: .75rem;
  padding-bottom: 0.3125rem;
  vertical-align: bottom;
  text-align: left;
  margin-top: 15px;
}

.epg-content {
  &-title, &-callsign {
    font-size: large;
    font-weight: 500;
  }

  &-description {
    opacity: 0.6;
  }
}

.content-edit-status > .team-card-notification-text {
  margin-left:0px;
  margin-top: 5px;
}

td {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered-table {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
